import PropTypes from "prop-types";
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import config from "../../config.json";
import theme from "../../theme";

const Layout = ({ children }) => (
  <Auth0Provider
    clientId={config.authId}
    domain={config.authdomain}
    redirectUri={typeof window !== "undefined" && window.location.origin}
  >
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
  </Auth0Provider>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Layout;
