/* eslint-disable prefer-destructuring */

import { extendTheme } from "@chakra-ui/react";

const briskBlue = "#0a2239";
const skyBlue = "#F3F6F9";
const blue = "#22384c";
const white = "#FFFFFF";
const textBlueLight = "#84909c";
const textWhite = "#b5bcc3";
const onlineGreen = "#32CD32";
const lightGreen = "#E5F8E5";
const mediumGreen = "#32CD32";
const errorRed = "#cc0000";

const breakpoints = ["400px", "768px", "1024px", "1200px", "1400px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
const transitionAll = "all 0.35s ease-in-out";
const buttonStyled = {
  common: {
    "&:hover": {
      bg: "onlineGreen",
      boxShadow: 2,
    },
    alignItems: "center",
    bg: "mediumGreen",
    borderColor: "mediumGreen",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: "2px",
    color: "white",
    display: "flex",
    fontSize: 0,
    fontWeight: "bold",
    justifyContent: "center",
    letterSpacing: 0,
    lineHeight: 2,
    minHeight: "calc(0.5rem * 2 + 1.6em)",
    pb: 5,
    pl: 8,
    pr: 8,
    pt: 5,
    textTransform: "uppercase",
    transition: transitionAll,
  },
  disabled: {
    opacity: ".65",
    pointerEvents: "none",
  },
  secondary: {
    "&:hover": {
      bg: "mediumGreen",
      boxShadow: 2,
      color: "white",
    },
    alignItems: "center",
    bg: "white",
    borderColor: "mediumGreen",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: "2px",
    color: "mediumGreen",
    display: "inline-flex",
    fontSize: 0,
    fontWeight: "bold",
    justifyContent: "center",
    letterSpacing: 0,
    lineHeight: 2,
    minHeight: "calc(0.5rem * 2 + 1.6em)",
    pb: 3,
    pl: 6,
    pr: 6,
    pt: 3,
    textTransform: "uppercase",
    transition: transitionAll,
  },
};

const grays = [
  "#f6f6f6", // 0
  "#f8f8f8", // 1
  "#fbfbfb", // 2
  "#bcbcbc", // 3
  "#808080", // 4
  "#383838", // 5
  "#000000", // 6
  "#ebeaea", // 7
  "#777777", // 8
  "#D8D8D8", // 9
];
// https://chakra-ui.com/docs/theming/theme https://chakra-ui.com/docs/theming/customize-theme refer this link for more values.
const theme = {
  breakpoints,
  colors: {
    bgMenu: blue,
    body: skyBlue,
    error: errorRed,
    grays,
    lightGreen,
    mediumGreen,
    onlineGreen,
    primary: briskBlue,
    text: {
      green: mediumGreen,
      light: textBlueLight,
      placeholder: grays[3],
      primary: briskBlue,
      secondary: textWhite,
    },
    white,
  },
  components: {
    Button: {
      variants: {
        primary: {
          ...buttonStyled.common,
        },
        primaryDisabled: {
          ...buttonStyled.common,
          ...buttonStyled.disabled,
        },
        secondary: {
          ...buttonStyled.secondary,
        },
        secondaryDisabled: {
          ...buttonStyled.secondary,
          ...buttonStyled.disabled,
        },
      },
    },
  },
  fonts: {
    primary: '"Nunito Sans", sans-serif',
  },
  fontSizes: ["0.833333rem", "1rem", "1.125rem", "2rem", "2.5rem"], // [13.333px, 16px, 18px, 32px, 40px]
  fontWeights: {
    black: 800,
    bold: 700,
    heavy: 900,
    light: 300,
    medium: 500,
    regular: 400,
    semiBold: 600,
  },
  letterSpacings: ["0.05em", "0.1em", "0.125em"],
  lineHeights: ["1", "1.4em", "1.6em", "2em"],
  radii: [
    0,
    "0.125rem",
    "0.25rem",
    "0.5rem",
    "1rem",
    "1.5rem",
    "9999px",
    "50%",
  ],
  shadows: [
    "none",
    "0 .125rem .25rem rgba(0,0,0,.075)",
    "0 .5rem 1rem rgba(0,0,0,.15)",
    "0 .5rem 1.5rem rgba(0,0,0,.175)",
    "0 12px 15px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)",
    "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
  ],
  sizes: {
    heights: { header: "92px", input: `calc(1.25em + 0.75rem * 2)` },
    maxWidths: {
      button: "14rem",
      lg: "1100px",
      md: "968px",
      sm: "700px",
      xl: "1200px",
    },
  },
  space: [
    "0",
    "0.25rem",
    "0.375rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.75rem",
    "2rem",
    "2.5rem",
    "3rem",
    "3.5rem",
    "4rem",
    "4.5rem",
    "5rem",
    "7rem",
    "8.5rem",
    "10rem",
  ],
  styles: {
    global: {
      "a, input": {
        "&:focus, &:active": {
          outline: "none",
        },
        font: "inherit",
        outline: "none",
      },
      "button[class^=chakra-modal__close-btn]": {
        ":hover": {
          bg: "white",
          color: "mediumGreen",
        },
      },
      "div[class^=chakra-alert]": {
        alignItems: "center",
        borderRadius: "10px",
        display: "flex",
      },
      "div[class^=chakra-alert__title]": {
        lineHeight: 0,
      },
      h1: {
        fontSize: [3, null, 4],
      },
      h2: {
        fontSize: [2, null, 3],
      },
      html: {
        height: "100%",
      },
      "html, body, #___gatsby, #gatsby-focus-wrapper": {
        bg: "body",
        color: "text.primary",
        fontSize: 1,
        fontWeights: "regular",
        height: "100%",
        mt: 0,
      },
      "span[class^=chakra-switch__track]": {
        borderRadius: "25px",
      },
    },
  },
  variant: {
    heading: {
      color: "bgMenu",
    },
    input: {
      primary: {
        "&:focus": { borderColor: "onlineGreen", boxShadow: 2 },
        "::placeholder": {
          color: "grays.3",
          fontSize: 2,
        },
        bg: "white",
        borderColor: "grays.7",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: "1px",
        boxShadow: 1,
        color: "text.primary",
        font: "fonts.primary",
        fontSize: 1,
        fontWeight: "regular",
        lineHeight: 1,
        maxWidth: "100%",
        minHeight: "40px",
        px: 4,
        py: 2,
      },
    },
  },
  zIndices: [0, 1, 10, 20, 50, 100, 9999],
};

export default extendTheme(theme);
